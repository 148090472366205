import {reactive} from "vue";
import apiClient from "@/services/api/apiClient";
import {isWretchError} from "@/services/api/utils";
import {v5} from "uuid";
import {getErrorText} from "@/components/callsUpload/errors";
import {FileInfo} from "@/components/callsUpload/FileInfo";

export class UploadableCall {
  // readonly file: File;
  readonly files: FileInfo[] = [];

  readonly data = reactive<UploadableCallData>({});
  readonly state = reactive<UploadableCallState>({
    error: null,
    status: `idle`,
  });

  get initialFile() {
    return this.files.length ? this.files[0] : null;
  }

  constructor(initialFile: File) {
    this.files[0] = new FileInfo(initialFile);
  }

  async upload(projectId: number) {
    if (this.state.status !== `ready`) return;
    if (!this.validateFieldsBeforeUpload()) return;

    try {
      this.state.status = `processing`;

      const extId = this.data.leadId;

      const managerName = `LEAD_DEFAULT_MANAGER`;
      const managerExtId = v5(managerName, v5.DNS);

      const resp = await apiClient.entitiesApi.createEntity({
        projectId: projectId,
        externalId: extId,
        startedAt: new Date().toISOString(),
        manager: {
          name: managerName,
          externalId: managerExtId,
        },
        data: {
          source: "UPLOAD",
          leadCustomerReason: this.data.customerReason,
          leadCustomerComment: this.data.customerComment,
          leadId: this.data.leadId,
          phone: this.data.phone,
          distributedAt: this.data.distributedAt,
          contractId: this.data.contractId
        },
        attachments: this.files.map((f) => ({
          filename: f.name,
          data: f.base64WoDataType,
        })),
      });

      this.state.status = `uploaded`;
    } catch (e) {
      if (isWretchError(e)) {
        this.state.error = getErrorText(e.json.message);
        this.state.status = `idle`;
      }
    }
  }

  validateFieldsBeforeUpload() {
    if (this.data.customerReason === null) {
      this.state.error = getErrorText(`NULL_CUSTOMER_REASON`);
      return false;
    }

    return true;
  }

  async validate(projectId: number) {
    try {
      this.state.error = null;
      this.state.status = `processing`;

      const resp = await apiClient.entitiesApi.validateFilename({
        projectId,
        filename: this.initialFile?.file.name,
      });

      this.data.phone = resp.phone;

      if (resp.ebkLead.data) {
        this.data.leadId = resp.ebkLead.data.leadId;
        this.data.distributedAt = resp.ebkLead.data.distributedAt;
        this.state.status = `ready`;
      } else {
        this.state.error = resp.ebkLead.message as string;
        this.state.status = `idle`;
      }
    } catch (e) {
      if (isWretchError(e)) {
        this.state.error = getErrorText(e.json.message);
      } else {
        console.error(e);
        this.state.error = e.toString();
      }
      this.state.status = `idle`;
    }
  }
}

type UploadableCallState = {
  error: string;
  status: `idle` | `processing` | `ready` | `uploaded`;
};

export type UploadableCallData = Partial<{
  phone: string;
  distributedAt: string;
  customerComment: string;
  customerReason: string;
  leadId: string;
  contractId: string;
}>;
